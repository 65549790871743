import Seo from "../components/Seo";
import * as React from "react";
import { Divider, Grid } from "@mui/material";
import ContactForm from "../components/ContactForm";
import { Layout } from '../components/Layout';

const Page404 = () => {
  return (
    <Layout seo={<Seo />}>
      <Grid container justifyContent="center">
        <Grid item xs={10} sx={{}}>
          <h1>Ooops, tahle sránka neexistuje</h1>
          <p>
            Bohužel jste narazili na stránku, která nexestuje. Budeme rádi, když
            nám dáte vědět, co jste hledali.
          </p>
          <br />
          <Divider />
          <br />
          <ContactForm />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Page404;
